import React from "react";

class MainMenu extends React.Component {
  render() {
    return (
      <>
      </>
    );
  }
}

export default MainMenu;
